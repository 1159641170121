//
// Theme style
//

// Initialize
@import 'init';

// Components
@import './core/components/components';

// Layout
@import 'layout/layout';

.characters {
  list-style: none;
  padding-left: 0;
}

.characters li {
  display: flex;
  align-items: center;
  border: solid 2px #d0d0d0;
  border-radius: 0.2em;
  padding: 0.5em 0.8em 0.5em 0.5em;
  margin-bottom: 1em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  overflow: hidden;
  flex-shrink: 0;
  width: 2em;
  height: 2em;
  background-color: #e8e8e8;
  padding: 0.5em;
  margin-right: 0.5em;
}

.characters-thumb img {
  display: block;
  width: 100%;
  height: auto;
}

.ql-editor {
  height: 100% !important;
}

.coupon-card:hover {
  background-color: #c7c0d6;
}

.backdrop-coupon {
  z-index: 1055;
  background-color: black;
  opacity: 0.5;
}

[type='date']::-webkit-calendar-picker-indicator {
  cursor: pointer;
}

.link-custom:hover {
  color: '#5014d0 !important';
}

// MOZILLA issue case
// [type='date']::-webkit-calendar-picker-indicator,
// [type='date']::-moz-calendar-indicator {
//   cursor: pointer;
// }

.icon {
  cursor: pointer;

  &:hover {
    color: #7e8299;
  }
}
