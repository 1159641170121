.gm-svpc,
.gm-style-mtc {
  display: none;
}

.gm-fullscreen-control {
  display: none;
}

.select-location {
  display: flex;
  gap: 8px;
  padding: 4px;
  flex-direction: row;
  width: 100%;
  align-items: center;

  &:hover {
    background: #dcedef;
    cursor: pointer;
    color: 'white';
  }
}

.maps-option {
  display: flex;
  gap: 2px;
  background-color: white;
  position: absolute;
  z-index: 1;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  align-content: center;
  top: 12%;
  overflow: auto;
  max-height: 60%;
  flex-direction: column;
  margin: 8px 14px 0;
  width: 98%;

  &__list {
    padding: 2px 4px;
    gap: 2px;
    font-size: 12px;
    display: flex;
    flex-direction: column;

    &:hover {
      background: #e9ecef;
      cursor: pointer;
      color: 'white';
    }
  }
}

.maps-marker {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  margin: -30px 0 0 -20px;
}

.maps-search {
  position: absolute;
  z-index: 1;
  margin: 12px;
  width: 98%;
}

.fetch-location {
  display: flex;
  gap: 2;
  flex-direction: column;
  margin-top: 8px;
}

// Bootstrap Loading Skeleton
%loading-skeleton {
  color: transparent;
  appearance: none;
  -webkit-appearance: none;
  background-color: #eee;
  border-color: #eee;

  &::placeholder {
    color: transparent;
  }
}

@keyframes loading-skeleton {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}
.skeleton-maps {
  &__title {
    height: 22px;
    width: 300px;
    margin-bottom: 8px;
  }

  &__address {
    height: 12px;
    width: 600px;
  }
}

.loading-skeleton {
  pointer-events: none;
  animation: loading-skeleton 1s infinite alternate;

  img {
    filter: grayscale(100) contrast(0%) brightness(1.8);
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  li,
  .btn,
  label,
  .skeleton-maps__title,
  .skeleton-maps__address {
    @extend %loading-skeleton;
  }
}
