//
// Root
//

// Light mode
@include color-mode(light) {
  // Page bg
  --#{$prefix}page-bg: #{$page-bg};

  // App blank bg color
  --#{$prefix}app-blank-bg: #{$app-blank-bg-color};

  // Header
  --#{$prefix}header-default-bg-color: #{get($header-config, default, bg-color)};
  --#{$prefix}header-fixed-bg-color: #{get($header-config, fixed, bg-color)};
  --#{$prefix}header-fixed-box-shadow: #{get($header-config, fixed, box-shadow)};

  // Aside
  --#{$prefix}aside-box-shadow: #{get($header-config, box-shadow)};
}

// Dark mode
@include color-mode(dark) {
  // Page bg
  --#{$prefix}page-bg: #{$page-bg-dark};

  // App blank bg color
  --#{$prefix}app-blank-bg: #{$app-blank-bg-color-dark};

  // Header
  --#{$prefix}header-default-bg-color: #{get($header-config, default, bg-color-dark)};
  --#{$prefix}header-fixed-bg-color: #{get($header-config, fixed, bg-color-dark)};
  --#{$prefix}header-fixed-box-shadow: #{get($header-config, fixed, box-shadow-dark)};

  // Aside
  --#{$prefix}aside-box-shadow: #{get($header-config, box-shadow-dark)};
}
