.change-password {
  background: white;
  padding: 24px;
  border-radius: 12px;

  &__container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 12px;
  }

  &__submit {
    text-align: center; 
  }
}
